<script>
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  name: 'ShowMoreList',
  props: {
    maxItems: {
      type: Number,
      default: 5,
    },
    expand: Boolean,
  },
  setup(props, { slots }) {
    const showMore = ref(props.expand)

    const defaultSlot = ref(slots.default())
    if (defaultSlot.value.length > 0) {
      console.log(defaultSlot.value[0].type)
      if (
        defaultSlot.value[0].type.toString() === 'Symbol()' ||
        defaultSlot.value[0].type.toString() === 'Symbol(Fragment)'
      ) {
        defaultSlot.value = defaultSlot.value[0].children
      }
    }

    console.log(defaultSlot.value, props.maxItems)
    const items = ref(defaultSlot.value.length)

    const displayedItems = computed(() => {
      if (showMore.value) {
        return defaultSlot.value
      } else {
        return defaultSlot.value.slice(0, props.maxItems)
      }
    })

    function toggleShowMore() {
      showMore.value = !showMore.value
    }

    return {
      items,
      showMore,
      displayedItems,
      toggleShowMore,
    }
  },
})
</script>

<template>
  <component
    :is="item"
    v-for="item in displayedItems"
    :key="item"
  />
  <div>
    <button
      v-if="items > maxItems"
      type="button"
      class="block text-blue underline decoration-1 underline-offset-2 hover:decoration-2 focus:outline-none focus:bg-yellow-500 focus:decoration-2"
      @click.prevent="toggleShowMore"
    >
      {{ showMore ? 'Show less' : 'Show more' }}
    </button>
  </div>
</template>
